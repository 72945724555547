import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import TopSubNav from "../components/TopSubNav"
import InsightsContainer from "../components/InsightsContainer"

export const query = graphql`
  {
    prismic {
      allInsightss {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            page_title
            _meta {
              uid
            }
          }
        }
      }
      allInsights(sortBy: publication_date_DESC, last: 500) {
        edges {
          node {
            feature_image
            short_description
            title
            _meta {
              uid
              lastPublicationDate
            }
            publication_date
            call_to_action
            resource_type
          }
        }
      }
    }
  }
`

const renderComponent = data => {
  const doc = data.prismic.allInsightss.edges.slice(0, 1).pop()
  if (!doc) return null

  const items = data.prismic.allInsights.edges

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav title={doc.node.page_title[0].text} />
      <InsightsContainer items={items} seoH1={doc.node.seo_h1} />
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
