import React from "react"
import { Link } from "gatsby"
import { ContentSection } from "./ContentSection"
import styled from "styled-components"
import ArrowRight from "@material-ui/icons/ArrowRightAlt"
import { RichText } from "prismic-reactjs"
import ClampLines from "react-clamp-lines"

const InsightsCardsContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  .card-container-link {
    color: black;
    text-decoration: none;
  }

  .card-container-link:nth-child(4n) {
    margin-right: 0;
  }
`

const InsightsCard = styled("div")`
  align-self: stretch;
  background: white;
  border: solid 1px #e3e3e3;
  border-radius: 3px;
  transform: translateY(0);
  transition: all 0.25s ease;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 200px 225px auto;
  grid-template-columns: auto;
  grid-template-rows: 200px 225px auto;

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    margin-bottom: 1rem;
    grid-template-rows: 200px auto auto;
  }

  .feature-image {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;
    position: relative;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: ${props =>
        props.imageUrl ? `url(${props.imageUrl})` : "none"};
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  .tag {
    background-color: #8ca3b5;
    color: white;
    padding: 3px 12px;
    border-radius: 5px;
    font-size: 12px;
    z-index: 1;
    display: inline-block;
    position: absolute;
    bottom: -10px;
    left: 1rem;
  }

  .content {
    padding: 1rem;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    grid-column: 1;
    grid-row: 2;
    overflow: hidden;

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  .footer {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    grid-column: 1;
    grid-row: 3;

    .read-more-link {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #0099cb;
    }
  }
`

const InsightsContainer = ({ items, seoH1 }) => {
  return (
    <>
      <ContentSection>
        <div className="inner-container">
          {seoH1 && RichText.render(seoH1)}
          <div className="anchor" id="Case_Studies" />
          {items && (
            <InsightsCardsContainer>
              {items.map(r => (
                <Link
                  to={`/resources/${r.node._meta.uid}`}
                  className="card-container-link"
                >
                  <InsightsCard
                    imageUrl={
                      r.node.feature_image && r.node.feature_image.url
                        ? r.node.feature_image.url
                        : "https://images.prismic.io/crgwebsite/a5754dbe-1713-4a19-a49a-20993bf5f6e6_crg-empty-image-logo.svg?auto=compress,format"
                    }
                  >
                    <div className="feature-image">
                      {r.node.resource_type && (
                        <div className="tag">{r.node.resource_type}</div>
                      )}
                    </div>

                    <div className="content">
                      {/* <h3>{RichText.asText(r.node.title)}</h3> */}
                      {/* <p>{RichText.asText(r.node.short_description)}</p> */}
                      <ClampLines
                        text={RichText.asText(r.node.title)}
                        id={`title-${r.node._meta.uid}`}
                        lines={2}
                        ellipsis="..."
                        innerElement="h3"
                        buttons={false}
                      />
                      <ClampLines
                        text={RichText.asText(r.node.short_description)}
                        id={`description-${r.node._meta.uid}`}
                        lines={4}
                        ellipsis="..."
                        innerElement="p"
                        buttons={false}
                      />
                    </div>
                    <div className="footer">
                      <Link
                        to={`/resources/${r.node._meta.uid}`}
                        className="read-more-link"
                      >
                        <span>
                          {r.node.call_to_action
                            ? RichText.asText(r.node.call_to_action)
                            : "Learn more"}
                        </span>{" "}
                        <ArrowRight />
                      </Link>
                    </div>
                  </InsightsCard>
                </Link>
              ))}
            </InsightsCardsContainer>
          )}
        </div>
      </ContentSection>
    </>
  )
}

export default InsightsContainer
